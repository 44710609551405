import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { FaCheckSquare, FaWindowClose, FaSyncAlt, FaCircleNotch  } from 'react-icons/fa';
import './DataComponent.css';

function DataComponent() {
  const [data, setData] = useState(null);
  const [loading, setLoading] = useState(false);
  const [cnpj, setCnpj] = useState('');

  const handleInputChange = (event) => {
    const { value } = event.target;
    const cnpjWithoutSpecialChars = value.replace(/[^\d]/g, ''); // Remove todos os caracteres não numéricos
    setCnpj(cnpjWithoutSpecialChars);
  };

  const handleConsultar = async () => {
    try {
      setLoading(true); // Ativa o indicador de carregamento
      const response = await axios.get(`https://api.cnpja.com/office/${cnpj}?registrations=BR`, {
        headers: {
          'Authorization': '1de5be3d-38ac-494d-90aa-47dd65549d7f-99b3432b-3c72-4df7-883a-6c40f61624b3',
        },
      });
      setData(response.data);
      setLoading(false); // Desativa o indicador de carregamento
    } catch (error) {
      console.log(error);
      setLoading(false); // Desativa o indicador de carregamento em caso de erro
    }
  };

  if (!data) {
    return (
      <div>
        <div className='agConsulta'>
          <input type='text' placeholder='Digite um CNPJ' value={cnpj} onChange={handleInputChange} />
          <button onClick={handleConsultar} disabled={loading} className='btConsultar'>
            Consultar
          </button>
          <button className='btLimpar'>
            Limpar <FaSyncAlt className='iconeLoad' />
          </button>
        </div>
        {loading ? ( // Verifica se o indicador de carregamento está ativo
          <div className="loading">
            <FaCircleNotch className="loading-icon" />
            <h1>Aguardando consulta...</h1>
          </div>
        ) : null}
      </div>
    );
  }

  const formatarCNPJ = (cnpj) => {
    return cnpj.replace(/^(\d{2})(\d{3})(\d{3})(\d{4})(\d{2})$/, '$1.$2.$3/$4-$5');
  }; 

  // Resto do seu código...
  const desiredFields = ['street', 'number', 'district', 'city', 'state', ]; // Informações desejadas do endereço
  const prefixes = {
    street: '',
    number: 'Nº',
    district: '\nBairro: ',
    city: '\nCidade: ',
    state: '\nUF: ',
  }; // Prefixos correspondentes aos campos

  const addressInfo = Object.values(data.address)
    .filter((value, index) => desiredFields.includes(Object.keys(data.address)[index]))
    .map((value, index) => `${prefixes[desiredFields[index]]}${value}`)
    .join(', ');
  const fullAddressInfo = `Endereço: ${addressInfo},\nPaís: ${data.address.country.name}, \nCEP: ${data.address.zip}`;

  const atividadesSecundarias = data.sideActivities;
  const atividadeSecRetorno = atividadesSecundarias.map(atividade => atividade.id + ' - ' + atividade.text);
  const atividadeSecRetornoFormatado = atividadeSecRetorno.join('\n');

  const _ie = data.registrations
  const _ieRetorno = _ie.map(registro => registro.number + registro.state)
  const _ieRetornoFormatado =  _ieRetorno.join('\n')

  const atividadePrincipal = data.mainActivity.id.toString();
  const idsAtividades = atividadesSecundarias.map(atividade => atividade.id);
  const arrAtividades = [].concat(atividadePrincipal, idsAtividades).map(elemento => String(elemento));
  //atividades permitidas
  const atividadesPermitidas = ['4642701', '4781400'];

  //validação de cnae
  const cnaeAtivo = atividadesPermitidas.some(el => arrAtividades.includes(el));

  let validacao = '';
  if (cnaeAtivo) {
    validacao = 1;
  } else validacao = 0;

  let emissaoCnae = '';
  if (cnaeAtivo) {
    emissaoCnae = `Com base no retorno da consulta, esse CNPJ possui CNAE para emissão de NF-e`;
  } else emissaoCnae = 'Nenhum CNAE registrado para esse CNPJ é permitido emissão de NF-e pela nossa empresa';

  return (
    <div>
      <div className='Consulta'>
        <input type='text' placeholder='Digite um CNPJ' value={cnpj} onChange={handleInputChange} />
        <button onClick={handleConsultar} disabled={loading} className='btConsultar'>
          Consultar
        </button>
        <button className='btLimpar'>
          Limpar <FaSyncAlt className='iconeLoad' />
        </button>
      </div>
      <div className='Consulta'>
        <h1>Resultado da Consulta:</h1>
        <hr />
        <h1>Situação Cadastral do CNPJ: {`${data.status.text}`} {data.status.id === 2 ? <FaCheckSquare className="icon enabled" /> : <FaWindowClose className="icon disabled" />}</h1>
        <h1 style={{ color: validacao === 1 ? 'green' : 'red' }}>
          {emissaoCnae}
        </h1>
        <table>
          <thead> 
            <tr> 
              <th>CNPJ</th>
              <th>Situação CNPJ</th>
              <th>Razão Social</th>
              <th>Nome Fantasia</th>
              <th>Telefone</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td>{formatarCNPJ(data.taxId)}</td>
              <td>{`${data.status.text}`} {data.status.id === 2 ? <FaCheckSquare className="icon enabled" /> : <FaWindowClose className="icon disabled" />}</td>
              <td>{data.company.name}</td>
              <td>{data.alias}</td>
              <td>{`(${data.phones[0].area}) ${data.phones[0].number}`}</td>
            </tr>
          </tbody>
        </table>
        <br/>
        <table>
          <thead>
            <tr>
              <th>Inscrição Estadual</th>
              <th>UF</th>
              <th>Status</th>
            </tr>
          </thead>
          <tbody>
            {_ie.map(registro => (
              <tr key={registro.number}>
                <td>{registro.number}</td>
                <td>{registro.state}</td>
                <td>
                {registro.enabled ? 'Ativo ' : 'Inativo '}
                {registro.enabled ? <FaCheckSquare className="icon enabled"/> : <FaWindowClose className="icon disabled"/>}
                </td>
              </tr>
            ))}
          </tbody>
        </table>
        <p>Razão Social: {data.company.name}</p>
        <p>Nome Fantasia: {data.alias}</p>
        <p>Telefone: {`(${data.phones[0].area}) ${data.phones[0].number}`}</p>
        <p><hr />{fullAddressInfo.split('\n').map((item, index) => <React.Fragment key={index}>{item}<br /></React.Fragment>)}</p>
        <hr />
        <p>Atividade Principal: {data.mainActivity.id} - {data.mainActivity.text} </p>
        <hr />

        <p>{atividadeSecRetornoFormatado.split('\n').map((linha, index) => (
          <React.Fragment key={index}>
            {linha}
            <br />
          </React.Fragment>
        ))}
        </p>
        <hr />
      </div>
    </div>
  );
}

export default DataComponent;
