import React from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import Consulta from './components/Consulta';
import DataComponent from './components/DataComponent';

const App = () => {
  return (
    <Router>
      <Routes>
        <Route path="/" element={<DataComponent />} />
        {/* Adicione outras rotas conforme necessário */}
      </Routes>
    </Router>
  );
};

export default App;